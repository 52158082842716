import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';
import HeadHelmet from "../../components/HeadHelmet/HeadHelmet";
import PageWrapper from "../../components/PageWrapper";

import imgNewsUrl1 from "../../assets/image/snorefreeswiss/news-snoring-apnea-devices-doctor-43.jpeg";
// import img2News from "../../assets/image/snorefreeswiss/news-snoring-apnea-devices-doctor-43.jpeg";
import img4News from "../../assets/image/snorefreeswiss/news-cpap-snore-apnea.jpeg";
import img5News from "../../assets/image/snorefreeswiss/bite-snoring-custom-magnet-tr.png";
import img6News from "../../assets/image/snorefreeswiss/news-apnea-drugs.jpeg";
import img7News from "../../assets/image/snorefreeswiss/news-wearable-tech-devices.jpeg";

import { renderTextWithFormatting } from "../../utils/renderTextWithFormatting";

const News = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "null", // cta, account, null
        }}
      >
        <HeadHelmet
          title='news2_title'
          description='news2_description'
          image={imgNewsUrl1}
        />

        <div className="inner-banner pt-20 pb-8">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-0 px-lg-8 text-center">
                  <h1 className="title gr-text-3 mb-9 heading-color">
                    {t('news2_title')}
                  </h1>
                </div>
              </Col>
              <Col xs="11">
                <div className="pt-lg-9 pb-8">
                  <img src={imgNewsUrl1} alt={t('news2_title') } className="w-100" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="main-block pb-21">
          <Container>
            <Row className="justify-content-center">
              <Col lg="10" xl="8">
                <div className="px-lg-8 px-xl-3">
                  <div className="single-block mb-11">
                    <p className="gr-text-9 text-color-opacity">
                      {renderTextWithFormatting(t('news2_b1'))} </p>
                      
                    {/* <div className="pt-lg-9 pb-8 p-10 pr-30">
                      <img src={img2News} alt={t('news2_img_caption')} className="w-100" />
                      <p className="gr-text-11" >{t('news2_img_caption')} </p>
                    </div> */}
                    <p className="gr-text-9 text-color-opacity">
                      {renderTextWithFormatting(t('news2_b2'))} </p>

                    <div className="pt-lg-9 pb-8 p-10 pr-30">
                      <img src={img4News} alt={t('news2_img3_caption')} className="w-100" />
                      <p className="gr-text-11" >{t('news2_img3_caption')} </p>
                    </div>
                    <p className="gr-text-9 text-color-opacity">
                      {renderTextWithFormatting(t('news2_b3'))} </p>

                    <div className="pt-lg-9 pb-8 p-10 pr-30">
                      <img src={img5News} alt={t('news2_img_caption')} className="w-100" />
                      <p className="gr-text-11" >{t('news2_img_caption')} </p>
                    </div>
                    <p className="gr-text-9 text-color-opacity">
                      {renderTextWithFormatting(t('news2_b4'))} </p>

                    <div className="pt-lg-9 pb-8 p-10 pr-30">
                      <img src={img6News} alt={t('news2_img_caption')} className="w-100" />
                      <p className="gr-text-11" >{t('news2_img_caption')} </p>
                    </div>
                    <p className="gr-text-9 text-color-opacity">
                      {renderTextWithFormatting(t('news2_b5'))} </p>


                    <div className="pt-lg-9 pb-8 p-10 pr-30">
                      <img src={img7News} alt={t('news2_img_caption')} className="w-100" />
                      <p className="gr-text-11" >{t('news2_img_caption')} </p>
                    </div>
                    <p className="gr-text-9 text-color-opacity">
                      {renderTextWithFormatting(t('news2_b6'))} </p>

                    <p className="gr-text-9 text-color-opacity">
                      {renderTextWithFormatting(t('news2_b7'))} </p>


                  </div>
                </div>
                {/* <div className="pt-10">
                  <Link
                    to="/anti-snore-bite-explained/"
                    className="btn-link with-icon text-blue gr-hover-y px-lg-8 px-xl-3"
                  >
                      {t('snore_bite_title')}&nbsp;<i className="fas fa-arrow-right"></i>
                  </Link>
                </div> */}
                <div className="pt-10 pb-20">
                  <Link
                    to="/contact"
                    className="btn btn-blue text-white gr-hover-y px-lg-9"
                  >
                    {t('news2_cta')}&nbsp;&nbsp;<i className="fas fa-arrow-right"></i>
                  </Link>
                </div>

              </Col>

            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default News;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
